import api from "./index";

const requiredFieldsProvided = address => {
  if (!address) return false;

  const { city, countryIso, name, postcode, street1 } = address;

  return [city, countryIso, name, postcode, street1].every(Boolean);
};

export default {
  async create(address) {
    try {
      const postcodeParam = requiredFieldsProvided(address)
        ? encodeURIComponent(address.postcode)
        : undefined;
      if (postcodeParam) {
        const response = await api.post(
          `/basket/addresses.json?pc_pattern=${postcodeParam}`,
          { address }
        );
        return response;
      }
      const response = await api.post("/basket/addresses.json", { address });
      return response;
    } catch (error) {
      return false;
    }
  },
  async index() {
    try {
      return await api.get("/account/api/addresses");
    } catch (error) {
      return false;
    }
  },
  async delete(id) {
    try {
      return await api.delete(`/account/api/addresses/${id}`);
    } catch (error) {
      return false;
    }
  },
  async update(id, address) {
    return api.put(`/account/api/addresses/${id}`, { address });
  },
  async createFromAccount(address, creatingPostcard = false) {
    return api.post("/account/api/addresses", {
      address,
      creating_postcard: creatingPostcard
    });
  },
  async updateFromCheckout(id, address) {
    return api.put(`/basket/addresses/${id}`, { address });
  },
  async deleteFromCheckout(id) {
    return api.delete(`/basket/addresses/${id}`);
  }
};
